import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { UnauthorizedError, getCurrentAuthenticatedUser } from 'lib/auth';
import Logger from 'lib/utils/Logger';

type useCurrentUserIdOptions = {
  updateThrottleTime?: number;
  useEffectDependency?: any;
};

const DEFAULT_THROTTLE_TIME = 300;

const useCurrentUserId = (options?: useCurrentUserIdOptions) => {
  const [currentUserId, setCurrentUserId] = useState<undefined | string>(
    undefined
  );
  const [currentUserIdLoading, setCurrentUserIdLoading] =
    useState<boolean>(true);

  const handleSetCurrentUserId = debounce(async () => {
    try {
      const result = await getCurrentAuthenticatedUser();

      setCurrentUserId(result.attributes.sub);
    } catch (error) {
      if (!(error instanceof UnauthorizedError)) {
        Logger.error('Something went wrong', error);
      }
      setCurrentUserId(undefined);
    } finally {
      setCurrentUserIdLoading(false);
    }
  }, options?.updateThrottleTime || DEFAULT_THROTTLE_TIME);

  useEffect(() => {
    handleSetCurrentUserId();
  }, options?.useEffectDependency);

  return { currentUserId, currentUserIdLoading, handleSetCurrentUserId };
};

export default useCurrentUserId;
