import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { ReactNode } from 'react';

import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ContentfulContentTypeEnum } from 'lib/contentful';
import { handleContentfulError } from 'lib/contentful/utils';

import styles from './commonRichTextTypes.module.scss';

const commonRichTextTypes = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      try {
        const { uri } = node.data;
        const isHelpLink = uri.startsWith(
          'https://verishop.freshdesk.com/support/home'
        );
        return (
          <a
            className={styles.hyperlinkAnchor}
            href={uri}
            rel={`noreferrer${isHelpLink ? ' noopener' : ''}`}
            target={isHelpLink ? '_blank' : ''}
          >
            {children}
          </a>
        );
      } catch (error) {
        handleContentfulError(INLINES.HYPERLINK, error);
        return null;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
      try {
        const { fields, sys } = node.data.target;
        return (
          sys.contentType.sys.id ===
            ContentfulContentTypeEnum.localizableLink && (
            <LocalizableLink
              className={styles.embeddedEntryLocalizableLink}
              {...fields}
            >
              {fields.displayText}
            </LocalizableLink>
          )
        );
      } catch (error) {
        handleContentfulError(INLINES.EMBEDDED_ENTRY, error);
        return null;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      try {
        const { url } = node.data.target.fields.file;
        return (
          <span className={styles.embeddedAssetIconContainer}>
            <img alt="" className={styles.embeddedAssetIcon} src={url} />
          </span>
        );
      } catch (error) {
        handleContentfulError(BLOCKS.EMBEDDED_ASSET, error);
        return null;
      }
    },
    [BLOCKS.PARAGRAPH]: (_: Block | Inline, children: ReactNode) => {
      try {
        return <span>{children}</span>;
      } catch (error) {
        handleContentfulError(BLOCKS.PARAGRAPH, error);
        return null;
      }
    },
  },
};

export default commonRichTextTypes;
