import classnames from 'classnames';
import dynamic from 'next/dynamic';
import {
  HTMLAttributes,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { FeaturedLink } from './FeaturedLink';
import NavigationMenuItem from './NavigationMenuItem';
const NavigationSubmenu = dynamic(() => import('./NavigationSubmenu'), {
  ssr: false,
});
import { Container } from 'components/Grid';

import { ILocalizableLink } from 'lib/contentful';

import { SlimmedSiteNavV2 } from 'types/app';

import styles from './NavigationMenu.module.scss';

export type NavigationMenuProps = {
  featuredLink?: ILocalizableLink;
  fontColor?: string;
  menuItems: SlimmedSiteNavV2[];
} & Pick<HTMLAttributes<HTMLElement>, 'className' | 'role'>;

// Navigation menu component that is located at the lower part of the page Header,
// below the Logo
const NavigationMenu = ({
  className,
  featuredLink,
  fontColor,
  menuItems,
  ...restHTMLProps
}: NavigationMenuProps): ReactElement => {
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<SlimmedSiteNavV2 | null>(null);
  const [clickedParentMenuItem, setClickedParentMenuItem] =
    useState<SlimmedSiteNavV2 | null>(null);

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const currentSubmenu = selectedMenuItem?.submenu;
  const currentMenuItemId = selectedMenuItem?.id;

  useEffect(() => {
    if (!clickedParentMenuItem && isSubmenuOpen) {
      setIsSubmenuOpen(false);
    } else if (clickedParentMenuItem?.submenu && !isSubmenuOpen) {
      setIsSubmenuOpen(true);
    }
  }, [clickedParentMenuItem, isSubmenuOpen]);

  // jsx-a11y requirement:
  const handleMainKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
    (event: KeyboardEvent<HTMLElement>) => {
      // see key names here: https://www.w3.org/TR/uievents-key/#keys-ui
      if (event?.key === 'Escape') {
        setSelectedMenuItem(null);
      }
    },
    [setSelectedMenuItem]
  );

  const closeSubmenu = () => {
    setIsSubmenuOpen(false);
    setSelectedMenuItem(null);
    setClickedParentMenuItem(null);
  };

  return (
    <nav
      {...restHTMLProps}
      className={classnames(styles.root, className)}
      onClick={() => {
        if (isSubmenuOpen) {
          setClickedParentMenuItem(null);
        } else {
          setClickedParentMenuItem(selectedMenuItem);
        }
      }}
      onKeyDown={handleMainKeyDown}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="menubar"
    >
      <Container>
        <div className={styles.menuBar}>
          <div className={styles.menuItemsContainer}>
            <div className={styles.scrollContainer}>
              {menuItems.map((menuItemProps, index) => (
                <NavigationMenuItem
                  {...menuItemProps}
                  className={styles.menuItem}
                  fontColor={menuItemProps.textColor ?? fontColor}
                  isSelected={menuItemProps.id === currentMenuItemId}
                  key={`${menuItemProps.id}~${index}`}
                  onMouseEnter={() => {
                    setSelectedMenuItem(menuItemProps);

                    if (!menuItemProps.submenu) {
                      setClickedParentMenuItem(null);
                    }
                  }}
                />
              ))}
            </div>
          </div>

          <div className={styles.fadeEnd}>&nbsp;</div>

          {featuredLink && (
            <FeaturedLink
              className={styles.featuredLink}
              fontColor={fontColor}
              linkProps={featuredLink}
            />
          )}
        </div>
      </Container>

      {currentSubmenu && selectedMenuItem && (
        <NavigationSubmenu
          {...currentSubmenu}
          className={styles.flyoutMenu}
          id={selectedMenuItem.id}
          isOpen={isSubmenuOpen}
          onOverlayClick={closeSubmenu}
        />
      )}
    </nav>
  );
};

export default NavigationMenu;
