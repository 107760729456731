import classnames from 'classnames';
import { FocusEventHandler, MouseEventHandler, ReactElement } from 'react';

import { NavigationSubmenuProps } from './NavigationSubmenu';
import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { ILocalizableLink } from 'lib/contentful';

import styles from './NavigationMenuItem.module.scss';

export type NavigationMenuItemProps = {
  displayText: string;
  fontColor?: string;
  id: string;
  isSelected?: boolean;
  link?: ILocalizableLink;
  onFocus?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  submenu?: NavigationSubmenuProps;
} & IDefaultComponentProps;

const NavigationMenuItem = ({
  className,
  displayText,
  fontColor,
  isSelected,
  link,
  onFocus,
  onMouseEnter,
  submenu,
}: NavigationMenuItemProps): ReactElement => {
  return (
    <div
      className={classnames(styles.root, className, {
        [styles.isSelected]: isSelected,
      })}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
    >
      {link && !submenu ? (
        <LocalizableLink
          className={styles.innerLink}
          dataAttributes={{ role: 'menuitem' }}
          fontColor={fontColor}
          {...link}
        />
      ) : (
        <span
          className={classnames(styles.innerLink, styles.cursorPointer)}
          role="menuitem"
          style={{ color: fontColor }}
        >
          {displayText}
        </span>
      )}
      <div className={styles.underline} />
    </div>
  );
};

export default NavigationMenuItem;
