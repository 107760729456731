import classnames from 'classnames';

import IDefaultComponentProps from 'components/defaultComponentProps';

import { HomeLink } from 'lib/links';

import VerishopLogo from 'assets/images/logotype/verishop-logo.inline.svg';

import styles from './HeaderLogo.module.scss';

const LABEL_HOMEPAGE = 'Browse to Verishop‘s home page';
const HeaderLogo = ({ className }: IDefaultComponentProps) => (
  <div className={classnames(styles.root, className)}>
    <HomeLink passHref>
      <a
        aria-label={LABEL_HOMEPAGE}
        className={styles.anchor}
        href="placeholder"
      >
        <div className={styles.logoContainer}>
          <VerishopLogo className={styles.logoSvg} />
        </div>
      </a>
    </HomeLink>
  </div>
);

export default HeaderLogo;
