import { useQuery } from '@apollo/client';
import { ReactElement, useCallback } from 'react';

import ClientOnly from 'components/ClientOnly/ClientOnly';
import { RewardCenterModal } from 'components/Modals/RewardCenterModal/RewardCenterModal';
import { DealEntryFromGetVerifiedDealsQuery } from 'components/Modals/RewardCenterModal/utils';
import convertCartData from 'data/cartDataConverter';
import { GET_CART_DETAILS_FOR_NAVBAR } from 'data/graphql/queries';
import { ShopifyCart } from 'data/graphql/types.shopify';

import { isCartIdAvailable } from 'lib/shopify/utils';
import { isBrowser } from 'lib/utils/browser';

type RewardsModalProps = {
  onOfferRedeemed?: (deal: DealEntryFromGetVerifiedDealsQuery) => Promise<void>;
  onOffersMarkedAsSeen?: (
    rewards: DealEntryFromGetVerifiedDealsQuery[]
  ) => void;
  setShowRewardsModal: (param: boolean) => void;
  showRewardsModal: boolean;
};

export const UserMenuRewardsModal = ({
  onOfferRedeemed,
  onOffersMarkedAsSeen,
  setShowRewardsModal,
  showRewardsModal,
}: RewardsModalProps): ReactElement => {
  const checkoutResponse = useQuery<{ checkout: ShopifyCart }>(
    GET_CART_DETAILS_FOR_NAVBAR,
    { skip: !isBrowser() || !isCartIdAvailable() }
  );

  const handleCloseClick = useCallback(
    () => setShowRewardsModal(false),
    [setShowRewardsModal]
  );

  const cartData =
    checkoutResponse?.data?.checkout &&
    convertCartData(checkoutResponse.data.checkout);
  const lineItems = cartData?.lineItems || [];

  return (
    <ClientOnly>
      <RewardCenterModal
        isOpen={showRewardsModal}
        lineItems={lineItems}
        onCloseClick={handleCloseClick}
        onOfferRedeemed={onOfferRedeemed}
        onOffersMarkedAsSeen={onOffersMarkedAsSeen}
      />
    </ClientOnly>
  );
};
