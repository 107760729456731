import classnames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';
import { ReactElement } from 'react';

import IconButton from 'components/Buttons/IconButton';
import IDefaultComponentProps from 'components/defaultComponentProps';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import ChevronRightIcon from 'assets/icons/ic-chevrone-right.inline.svg';

import styles from './SectionSubmenuItem.module.scss';

export type SectionSubmenuItemProps = {
  'aria-label'?: string;
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onKeyPress?: () => void;
  showSubmenuExpander?: boolean;
} & IDefaultComponentProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const SectionSubmenuItem = (props: SectionSubmenuItemProps): ReactElement => {
  const { children, className, onClick, onKeyPress, showSubmenuExpander } =
    props;

  return (
    <div
      aria-expanded={false}
      aria-label={props['aria-label']}
      className={classnames(styles.root, className)}
      onClick={onClick}
      onKeyPress={keyPressHandler({ Enter: onKeyPress })}
      role="button"
      tabIndex={0}
    >
      <div className={styles.content}>{children}</div>

      {showSubmenuExpander && (
        <IconButton
          IconComponent={ChevronRightIcon}
          aria-label={props['aria-label']}
          className={styles.openSubmenuButton}
          tabIndex={-1}
        />
      )}
    </div>
  );
};

export default SectionSubmenuItem;
