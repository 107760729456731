import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import Router from 'next/router';
import {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useReducer,
} from 'react';

import AccountIconMenu from './AccountIconMenu';
import AuthMenu from './AuthMenu/AuthMenu';
import { UserMenuRewardsModal } from './UserMenuRewardsModal';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import { DealEntryFromGetVerifiedDealsQuery } from 'components/Modals/RewardCenterModal/utils';
import { GET_CART_DETAILS_FOR_NAVBAR } from 'data/graphql/queries';
import { ShopifyCart } from 'data/graphql/types.shopify';
import { TAB_ID } from 'explorer/types/Profile';

import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { checkIsUserLoggedIn } from 'lib/auth';
import { calculateNumberOfItems } from 'lib/cart';
import { getBundleId } from 'lib/cart/bundlesUtils';
import useCurrentUserId from 'lib/hooks/auth/useCurrentUserId';
import { CartLink } from 'lib/links';
import { pageRoutes } from 'lib/routes';
import { isCartIdAvailable } from 'lib/shopify/utils';
import { isBrowser } from 'lib/utils/browser';

import { GET_VARIANTS_FOR_USER_MENU } from './UserMenu.gql';

import AccountIcon from 'assets/icons/ic-account.inline.svg';
import BagIcon from 'assets/icons/ic-bag-false.inline.svg';
import BookmarkIcon from 'assets/icons/ic-bookmark-false.inline.svg';
import RewardsIcon from 'assets/icons/ic-rewards.inline.svg';

import styles from './UserMenu.module.scss';
import sharedStyles from 'components/sharedComponentStyles.module.scss';

export const TEST_ID_CART_ICON_COUNT_BADGE = 'TEST_ID_CART_ICON_COUNT_BADGE';

const LABEL_BOOKMARKS = 'Check your saved collections';
const LABEL_CART = 'See your shopping bag contents';
const LABEL_ACCOUNT = 'Account menu, press RETURN to open';
const LABEL_LOGIN = 'Account, press RETURN to login';
const LABEL_REWARDS = 'Check your rewards offers';

const TOP_BAR_ANALYTICS_SOURCE_VALUE = 'top bar';

const CartItemsCount = () => {
  const checkoutResponse = useQuery<{ checkout: ShopifyCart }>(
    GET_CART_DETAILS_FOR_NAVBAR,
    { skip: !isBrowser() || !isCartIdAvailable() }
  );

  // If a lineItem belongs to a sku, collect the bundle's sku
  const lineItemEdges = checkoutResponse?.data?.checkout.lineItems.edges ?? [];
  const bundleSkus = lineItemEdges.map(edge => getBundleId(edge.node));

  // Fetch data for all of the bundles
  const bundleVariantResponse = useQuery(GET_VARIANTS_FOR_USER_MENU, {
    skip: bundleSkus.length === 0,
    variables: { skus: bundleSkus },
  });

  // Exit early if the data has not yet loaded
  if (!checkoutResponse?.data || !bundleVariantResponse?.data) {
    return null;
  }

  const count = calculateNumberOfItems(
    lineItemEdges.map(edge => edge.node),
    bundleVariantResponse.data.productVariant
  );

  if (count) {
    return (
      <div
        className={styles.cartItemsCount}
        data-test-id={TEST_ID_CART_ICON_COUNT_BADGE}
      >
        {count}
      </div>
    );
  }

  return null;
};

type UserMenuAction =
  | { type: 'SHOW_ACCOUNT_MODAL'; value: boolean }
  | { type: 'SHOW_AUTH_MENU'; value: boolean }
  | { type: 'SHOW_REWARDS_MODAL'; value: boolean }
  | { type: 'SET_LOGGED_IN_USER_ID'; value: string | undefined };

type UserMenuState = {
  isAccountMenuOpen: boolean;
  isAuthMenuOpen: boolean;
  isRewardsModalOpen: boolean;
  loggedInUserId?: string;
};

const initialState: UserMenuState = {
  isAccountMenuOpen: false,
  isAuthMenuOpen: false,
  isRewardsModalOpen: false,
};

const UserMenuReducer = (
  state: UserMenuState,
  action: UserMenuAction
): UserMenuState => {
  switch (action.type) {
    case 'SHOW_REWARDS_MODAL': {
      return {
        ...state,
        isRewardsModalOpen: action.value,
      };
    }

    case 'SHOW_AUTH_MENU': {
      return {
        ...state,
        isAuthMenuOpen: action.value,
      };
    }

    case 'SHOW_ACCOUNT_MODAL': {
      return {
        ...state,
        isAccountMenuOpen: action.value,
      };
    }
    case 'SET_LOGGED_IN_USER_ID': {
      return {
        ...state,
        loggedInUserId: action.value,
      };
    }

    default: {
      return state;
    }
  }
};

type UserMenuProps = {
  className?: string;
  shouldOpenAccountMenuOnClick?: boolean;
  showProfileIcon?: boolean;
};

const UserMenu = (props: UserMenuProps): ReactElement => {
  const {
    className,
    shouldOpenAccountMenuOnClick = true,
    showProfileIcon = true,
  } = props;
  const { currentUserId } = useCurrentUserId();

  const [state, dispatch] = useReducer(UserMenuReducer, initialState);

  /**
   * Accepts an event and will return a promise with the auth status of the user.
   * If not authenticated, it will open the auth modal and return false.
   * @param event
   * @returns {boolean} true if authenticated, false if not
   */
  const requireAuthOnClick = async (
    event: MouseEvent | KeyboardEvent
  ): Promise<boolean> => {
    if (event.type === 'click' || (event as KeyboardEvent).key === 'Enter') {
      const isUserLoggedIn = await checkIsUserLoggedIn();
      if (isUserLoggedIn) {
        return true;
      }
    }
    return false;
  };

  const handleAccountClick = (event: MouseEvent | KeyboardEvent) => {
    requireAuthOnClick(event).then(isAuthenticated => {
      if (isAuthenticated) {
        if (shouldOpenAccountMenuOnClick) {
          dispatch({
            type: 'SHOW_ACCOUNT_MODAL',
            value: !state.isAccountMenuOpen,
          });
        } else {
          const { displayUrl, internalUrl } =
            pageRoutes.account.profile.landing;
          Router.push(internalUrl(), displayUrl());
        }
      } else {
        dispatch({
          type: 'SHOW_AUTH_MENU',
          value: !state.isAuthMenuOpen,
        });
      }
    });
  };

  const handleRewardsClick = () => {
    const { displayUrl, internalUrl } = pageRoutes.rewards;
    Router.push(internalUrl(), displayUrl());
  };

  const handleBookmarkClick = (event: MouseEvent | KeyboardEvent) => {
    requireAuthOnClick(event).then(isAuthenticated => {
      if (isAuthenticated) {
        const { displayUrl, internalUrl } = pageRoutes.account.profile.tab;
        Router.push(internalUrl(), displayUrl(TAB_ID.COLLECTIONS));
      } else {
        dispatch({
          type: 'SHOW_AUTH_MENU',
          value: !state.isAuthMenuOpen,
        });
      }
    });
  };

  const onOfferRedeemed = useCallback(
    async (offer: DealEntryFromGetVerifiedDealsQuery) => {
      trackEvent(AnalyticsEvent.OFFER_APPLIED, {
        offerId: offer?.id || null,
        source: TOP_BAR_ANALYTICS_SOURCE_VALUE,
      });
    },
    []
  );

  return (
    <div className={classnames(styles.root, className)}>
      <UserMenuRewardsModal
        onOfferRedeemed={onOfferRedeemed}
        setShowRewardsModal={value =>
          dispatch({ type: 'SHOW_REWARDS_MODAL', value })
        }
        showRewardsModal={state.isRewardsModalOpen}
      />
      <button
        aria-label={LABEL_REWARDS}
        className={classnames(
          styles.menuItem,
          styles.badgedButton,
          styles.rewardsButton
        )}
        onClick={handleRewardsClick}
        onKeyPress={handleRewardsClick}
        tabIndex={0}
      >
        <RewardsIcon className={classnames(styles.icon)} />
      </button>
      <button
        aria-label={LABEL_BOOKMARKS}
        className={styles.menuItem}
        onClick={handleBookmarkClick}
      >
        <BookmarkIcon className={styles.icon} />
      </button>
      <button
        aria-label={LABEL_CART}
        className={classnames(styles.menuItem, styles.badgedButton)}
      >
        <CartLink>
          <span className={classnames(sharedStyles.link, styles.innerLink)}>
            <BagIcon className={classnames(styles.icon, styles.bagIcon)} />
            <ClientOnly>
              <CartItemsCount />
            </ClientOnly>
          </span>
        </CartLink>
      </button>
      {showProfileIcon && (
        <button
          aria-expanded={state.isAccountMenuOpen}
          aria-haspopup="true"
          aria-label={currentUserId ? LABEL_ACCOUNT : LABEL_LOGIN}
          className={classnames(styles.menuItem, styles.profileMenuIcon)}
          onKeyPress={handleAccountClick}
          tabIndex={0}
        >
          <AccountIcon className={styles.icon} onClick={handleAccountClick} />
          <AccountIconMenu
            isOpen={state.isAccountMenuOpen}
            onCloseClick={() => {
              dispatch({ type: 'SHOW_ACCOUNT_MODAL', value: false });
            }}
          />
        </button>
      )}
      <AuthMenu
        isOpen={state.isAuthMenuOpen}
        onCloseClick={() => {
          dispatch({ type: 'SHOW_AUTH_MENU', value: false });
        }}
      />
    </div>
  );
};

export default UserMenu;
