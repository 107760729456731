import { ReactElement } from 'react';

import getConfig from 'config/config';

const BUILD_VERSION = process.env.BUILD_VERSION as string;
const TRUE_NODE_ENV = process.env.TRUE_NODE_ENV as string;
const PAGE_TITLE_SUFFIX = '| Verishop';

export const getAssetUrl = (assetPath: string): string => {
  if (['beta', 'staging', 'production'].includes(TRUE_NODE_ENV)) {
    return `${getConfig('baseUrl')}/assets/${BUILD_VERSION}/${assetPath}`;
  }
  // return a relative URL for local
  return `/assets/${assetPath}`;
};

export const DEFAULT_IMAGE = getAssetUrl(
  'images/logotype/verishop-square-white-on-navy-1600x1600.png'
);

/**
 * Tags must not be wrapped in <Fragment> and should be returned as arrays
 */

export const generatePageTitle = (title: string): JSX.Element[] => {
  const shouldHideSuffix = title?.includes(PAGE_TITLE_SUFFIX);
  const pageTitle = shouldHideSuffix ? title : `${title} ${PAGE_TITLE_SUFFIX}`;
  return [<title key="title">{pageTitle}</title>];
};

export const generateDescriptionTags = (description = ''): JSX.Element[] => {
  const truncated = description.substring(0, 300);
  return [
    <meta content={truncated} key="desc" name="description" />,
    <meta content={truncated} key="og-desc" property="og:description" />,
    <meta content={truncated} key="tw-desc" name="twitter:description" />,
  ];
};

export const generateImageTags = (imageUrl: string): JSX.Element[] => [
  <meta content={imageUrl} key="og-image" property="og:image" />,
  <meta content={imageUrl} key="tw-image" name="twitter:image" />,
];

export const generateCanonicalTags = (url: string): JSX.Element[] => [
  <link href={url} key="canonical" rel="canonical" />,
  <meta content={url} key="og-url" property="og:url" />,
  <meta content={url} key="tw-url" name="twitter:url" />,
];

const generateDefaultTags = (): JSX.Element[] => [
  <meta content="Verishop" key="sitename" property="og:site_name" />,
];

export const generateRobotsTags = (tag: string): JSX.Element[] => [
  <meta content={tag} key="robots" name="robots" />,
];

export type MetaTags = {
  canonicalUrl?: string;
  description?: string;
  imageUrl?: string;
  robots?: string;
  title: string;
};

const generateMetaTags = (props: MetaTags): JSX.Element => {
  const {
    canonicalUrl = '',
    description = '',
    imageUrl = DEFAULT_IMAGE,
    robots = '',
    title,
  } = props;
  const metaTags = [
    ...generatePageTitle(title),
    ...generateDescriptionTags(description),
    ...generateImageTags(imageUrl),
    ...generateDefaultTags(),
  ];

  if (canonicalUrl) {
    metaTags.push(...generateCanonicalTags(canonicalUrl));
  }
  if (robots) {
    metaTags.push(...generateRobotsTags(robots));
  }

  return <>{metaTags}</>;
};

const getFaviconTags = (): ReactElement => (
  <>
    <meta
      content={getAssetUrl('xml/browserconfig.xml')}
      name="msapplication-config"
    />
    <link
      href={getAssetUrl('images/logomark/v-dash-navy-on-beige-16x16.ico')}
      rel="shortcut icon"
      type="image/x-icon"
    />
    <link
      href={getAssetUrl('images/logomark/v-dash-navy-on-beige-16x16.png')}
      rel="icon"
      sizes="16x16"
      type="image/png"
    />
    <link
      href={getAssetUrl('images/logomark/v-dash-navy-on-beige-32x32.png')}
      rel="icon"
      sizes="32x32"
      type="image/png"
    />
    <link
      href={getAssetUrl(
        'images/logotype/verishop-square-white-on-navy-2lines-180x180.png'
      )}
      rel="apple-touch-icon"
    />
  </>
);

export { getFaviconTags, generateMetaTags };
