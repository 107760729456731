import { ReactElement } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';
import SiteWideBanner from 'components/SiteWideBanner/SiteWideBanner';

import Logger from 'lib/utils/Logger';

type SiteWideBannerContainerProps = {
  isSiteWideBanner2?: boolean;
} & IDefaultComponentProps;

const DEFAULT_BANNER_DISMISSED_STORAGE_KEY = 'isSitewideBannerDismissed';

const SiteWideBannerContainer = (
  props: SiteWideBannerContainerProps
): ReactElement | null => {
  const bannerProps = {
    bannerKeyName: DEFAULT_BANNER_DISMISSED_STORAGE_KEY,
    ...props,
  };
  try {
    // TODO [CH6300]: Use this space for getting data from Site Furniture
    return <SiteWideBanner {...bannerProps} />;
  } catch (error) {
    if (error instanceof Error) {
      Logger.error(
        'Unable to render SiteWideBanner due to an unexpected error.',
        error
      );
    }

    return null;
  }
};

export default SiteWideBannerContainer;
