import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { ReactElement } from 'react';

import FooterColumn, { IFooterColumnProps } from './FooterColumn';
import FooterLink, { FooterLinkProps } from './FooterLink';
import FooterSection from './FooterSection';
import IDefaultComponentProps from 'components/defaultComponentProps';
import { Column, Container, Row } from 'components/Grid';
import NewsLetterInput from 'components/InputFields/NewsLetterTextInput/NewsLetterInput';
import DMCAModal from 'components/Modals/DMCAModal/DMCAModal';

import { FOOTER_APP_URL } from 'lib/ios/constants';
import Logger from 'lib/utils/Logger';

import AppStoreBadge from 'assets/images/badge-appstore.inline.svg';

import styles from './Footer.module.scss';

export type FooterOptions = {
  hideAppDownloadBanner?: boolean;
};

export type FooterProps = {
  bottomLinks: FooterLinkProps[];
  columns: IFooterColumnProps[];
  options?: FooterOptions;
} & IDefaultComponentProps;

const LABEL_APP = 'Click to download Verishop‘s iPhone application';

const renderBottomLinks = (links: FooterLinkProps[]) => {
  if (isEmpty(links)) {
    Logger.error('Something went wrong. Footer bottomLinks are not loading');
    return null;
  }

  return (
    <div>
      {links.map((linkProps: FooterLinkProps) => (
        <FooterLink
          className={styles.bottomLink}
          key={linkProps.displayText}
          {...linkProps}
        />
      ))}
      <DMCAModal />
    </div>
  );
};

const renderGenericColumns = (columns: IFooterColumnProps[]) => {
  if (isEmpty(columns)) {
    Logger.error('Something went wrong. Footer columns are not loading');
    return null;
  }

  const sortedColumns = sortBy(columns, column => column.position);

  return sortedColumns.map((columnData: IFooterColumnProps) => (
    <FooterColumn
      className={styles.column}
      key={columnData.position}
      {...columnData}
    />
  ));
};

const renderInteractiveColumn = (hideAppDownloadBanner: boolean) => {
  return (
    <FooterColumn className={styles.column} position={1}>
      {!hideAppDownloadBanner && (
        <FooterSection items={[]} title="">
          <a
            aria-label={LABEL_APP}
            href={FOOTER_APP_URL}
            rel="noreferrer nofollow"
            target="_blank"
          >
            <AppStoreBadge className={styles.appStoreIcon} />
          </a>
        </FooterSection>
      )}
      <div className={styles.newsLetterInputHeader}>Stay in touch</div>
      <NewsLetterInput textInputClassname={styles.newsLetterInput} />
    </FooterColumn>
  );
};

const Footer = ({
  bottomLinks,
  className,
  columns,
  options: { hideAppDownloadBanner = false } = {},
}: FooterProps): ReactElement => (
  <div className={classnames(styles.root, className)} role="contentinfo">
    <Container>
      <Row>
        {renderInteractiveColumn(hideAppDownloadBanner)}
        {renderGenericColumns(columns)}
      </Row>
      <Row>
        <Column xs={12}>
          <div className={styles.bottomLinksContainer}>
            {renderBottomLinks(bottomLinks)}
          </div>
        </Column>
      </Row>
    </Container>
  </div>
);

export default Footer;
