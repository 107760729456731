import classnames from 'classnames';
import compact from 'lodash/compact';
import { ReactElement, useCallback } from 'react';

import { Reward } from './Reward';
import {
  DealEntryFromGetVerifiedDealsQuery,
  getLineItemTotal,
  RedemptionState,
} from './utils';
import SpinnerInlineCSS from 'components/SpinnerInlineCSS/SpinnerInlineCSS';
import { useLiveShopDiscountDetail } from 'explorer/lib/hooks/useLiveShopDiscountDetail';

import { useMarkRewardsSeen } from 'lib/hooks/rewards/useMarkRewardsSeen';
import { useRewards } from 'lib/hooks/rewards/useRewards';

import { CartLineItem as CartLineItemType } from 'data/graphql/types';

import NoOffersIcon from 'assets/images/no-offers-badge.inline.svg';

import styles from './RewardCenterModal.module.scss';

export const APPLIED_OFFER_STORAGE_KEY = 'verishop_current_applied_offer';

type RewardListProps = {
  className?: string;
  disableRedeemButtons?: boolean;
  lineItems?: CartLineItemType[];
  onOfferRedeemedSuccess?: (
    offer: DealEntryFromGetVerifiedDealsQuery
  ) => Promise<void> | void;
  onOffersMarkedAsSeen?: (
    rewards: DealEntryFromGetVerifiedDealsQuery[]
  ) => void;
  /**
   * Condition to run and fetch verified deals. Great for if this is used
   * inside of a modal to save on initial network calls if they are not
   * necessary
   */
  runCondition: boolean;
};

export const RewardList = ({
  className,
  disableRedeemButtons = false,
  lineItems = [],
  onOfferRedeemedSuccess,
  onOffersMarkedAsSeen,
  runCondition,
}: RewardListProps): ReactElement => {
  const {
    appliedOffer,
    errorMessage,
    loading: offersLoading,
    onRedeemOffer,
    redeemOfferState,
    redeemableOffers,
  } = useRewards(runCondition, lineItems, onOfferRedeemedSuccess);

  const { liveShopDiscountDetails, loading: liveShopLoading } =
    useLiveShopDiscountDetail({
      ids: compact(redeemableOffers.map(x => x?.liveShopId)),
    });

  const onRedeemClick = useCallback(
    (offer: DealEntryFromGetVerifiedDealsQuery) => {
      if (redeemOfferState === 'LOADING') {
        return;
      }
      onRedeemOffer(offer);
    },
    [onRedeemOffer, redeemOfferState]
  );

  useMarkRewardsSeen(redeemableOffers, onOffersMarkedAsSeen);

  const cartTotal = getLineItemTotal(lineItems);

  const getRedemptionState = useCallback(
    (deal: DealEntryFromGetVerifiedDealsQuery): RedemptionState => {
      if (!deal) {
        return RedemptionState.DISABLED;
      }

      const isCurrentlyAppliedDeal = deal.id === appliedOffer?.id;

      if (redeemOfferState === 'LOADING' && isCurrentlyAppliedDeal) {
        return RedemptionState.LOADING;
      }

      if (redeemOfferState === 'REDEEMED' && isCurrentlyAppliedDeal) {
        return RedemptionState.APPLIED;
      }

      if (
        redeemOfferState === 'LOADING' ||
        (deal.minimumSpend || 0) > cartTotal
      ) {
        return RedemptionState.DISABLED;
      }

      return RedemptionState.AVAILABLE;
    },
    [appliedOffer, cartTotal, redeemOfferState]
  );

  return (
    <div className={classnames(className)}>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      {offersLoading || liveShopLoading ? (
        <div className={styles.loading}>
          <SpinnerInlineCSS className={styles.loadingSpinner} />
        </div>
      ) : (
        <>
          {redeemableOffers?.map(reward => {
            if (!reward) {
              return null;
            }
            return (
              <Reward
                data={reward}
                disableRedeemButton={disableRedeemButtons}
                key={reward.id}
                liveShopDiscountDetail={
                  reward.liveShopId
                    ? liveShopDiscountDetails[reward.liveShopId]
                    : undefined
                }
                onRedeemOffer={onRedeemClick}
                redemptionState={getRedemptionState(reward)}
              />
            );
          })}
          {redeemableOffers?.length === 0 && (
            <div className={styles.zeroState}>
              <NoOffersIcon className={styles.zeroStateSvg} />
              <p className={styles.zeroStateCopy}>You do not have any Offers</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
