import classnames from 'classnames';

import FooterSection, { IFooterSectionProps } from './FooterSection';
import IDefaultComponentProps from 'components/defaultComponentProps';
import { Column } from 'components/Grid';

import styles from './FooterColumn.module.scss';

export interface IFooterColumnProps extends IDefaultComponentProps {
  children?: unknown;
  position: number;
  sections?: IFooterSectionProps[];
}

const FooterColumn = ({
  children,
  className,
  sections,
}: IFooterColumnProps) => (
  <Column sm={3} xs={12}>
    <div className={classnames(styles.root, className)}>
      {sections &&
        sections.map((section: IFooterSectionProps) => (
          <FooterSection
            className={styles.section}
            key={section.title}
            {...section}
          />
        ))}

      {children}
    </div>
  </Column>
);

export default FooterColumn;
