import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

import { isBrowser } from 'lib/utils/browser';

type LazyLoadProps = {
  children: ReactNode;
  className?: string;
  placeholderClassName?: string;
  triggerOnce?: boolean;
  verticalOffsetBottom?: number;
  verticalOffsetTop?: number;
};

const LazyLoad = ({
  children,
  className,
  placeholderClassName,
  triggerOnce = true,
  verticalOffsetBottom = 0,
  verticalOffsetTop = 0,
}: LazyLoadProps) => {
  const [ref, inView] = useInView({
    rootMargin: `0px ${verticalOffsetTop}px ${verticalOffsetBottom}px 0px`,
    triggerOnce,
  });

  // Check if LazyLoad is supressed (for example by a Prerender)
  const isLazyLoadSuppressed = isBrowser() && window?.isLazyLoadSuppressed;

  // if we have a placeholder style provided, use a placeholder element,
  // otherwise, use the old logic, return null
  const placeholderElement = placeholderClassName ? (
    <div className={placeholderClassName} />
  ) : null;

  return (
    <div className={className} ref={ref}>
      {inView || isLazyLoadSuppressed ? children : placeholderElement}
    </div>
  );
};

export default LazyLoad;
