import { HTMLAttributes } from 'react';

import SideMenuMainContent from './SideMenuMainContent';

import Logger from 'lib/utils/Logger';

export type SideMenuContainerProps = HTMLAttributes<HTMLDivElement>;

const SideMenuContainer = (props: SideMenuContainerProps) => {
  try {
    return <SideMenuMainContent {...props} />;
  } catch (error) {
    Logger.error(
      'Unexpected error when trying to render SideMenuContainer',
      error
    );
    return null;
  }
};

export default SideMenuContainer;
