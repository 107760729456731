import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { LoginLink, SignupLink } from 'lib/links';

import styles from './AuthMenu.module.scss';
import sharedStyles from 'components/sharedComponentStyles.module.scss';

interface AuthMenuProps {
  isOpen: boolean;
  onCloseClick(): void;
}

const AuthMenu = ({ isOpen, onCloseClick }: AuthMenuProps) => {
  const { asPath } = useRouter();
  const componentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    event => {
      if (
        isOpen &&
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        onCloseClick();
      }
    },
    [isOpen, onCloseClick]
  );

  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') {
        onCloseClick();
      }
    },
    [isOpen, onCloseClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', onKeydown, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', onKeydown, true);
    };
  }, [handleClickOutside, onKeydown]);

  return (
    <div className={classnames(styles.root, { [styles.isOpen]: isOpen })}>
      <div className={styles.menuContainer} ref={componentRef}>
        <ul>
          <li className={styles.menuItem}>
            <LoginLink
              className={sharedStyles.link}
              redirectUrl={asPath}
              rel="nofollow"
            >
              Login
            </LoginLink>
          </li>
          <li className={styles.menuItem}>
            <SignupLink
              className={sharedStyles.link}
              redirectUrl={asPath}
              rel="nofollow"
            >
              Sign up
            </SignupLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthMenu;
