import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
  DealEntryFromGetVerifiedDealsQuery,
  isRewardLocked,
} from 'components/Modals/RewardCenterModal/utils';

import Logger from 'lib/utils/Logger';

import { MARK_REWARDS_SEEN_MUTATION } from './useMarkRewardsSeen.gql';

import {
  GenericMutationResponse,
  MutationMarkVerifiedDealsAsSeenArgs,
} from 'types/generated/api';

/**
 * Marks the rewards passed in as seen via GraphQL mutation
 * @param rewards - The rewards to mark as seen. Can be null or undefined
 * @param onOffersMarkedAsSeen - Optional callback to update UI when offers are marked as seen. Runs optimistically to feel fast/snappy/awesome
 * @returns undefined
 */
export const useMarkRewardsSeen = (
  rewards?: DealEntryFromGetVerifiedDealsQuery[] | null,
  onOffersMarkedAsSeen?: (rewards: DealEntryFromGetVerifiedDealsQuery[]) => void
): void => {
  const [didSendSeenMutation, setDidSendSeenMutation] =
    useState<boolean>(false);
  const [markRewardsAsSeen] = useMutation<
    GenericMutationResponse,
    MutationMarkVerifiedDealsAsSeenArgs
  >(MARK_REWARDS_SEEN_MUTATION);

  useEffect(() => {
    const runSeenEffect = async () => {
      if (!rewards) {
        return;
      }
      const hasUnseen = rewards.some(
        reward => reward && !isRewardLocked(reward) && !reward.isSeen
      );
      if (hasUnseen && rewards && !didSendSeenMutation) {
        // Send a mutation to mark redeemableOffers as seen
        const verifiedDealIds: string[] = [];
        rewards.forEach(offer => {
          if (offer && offer.id) {
            verifiedDealIds.push(offer.id);
          }
        });

        try {
          // Optimistically run callback to mark offers as seen.
          // This is to make the UI feel snappy and quick
          onOffersMarkedAsSeen?.(rewards);
          await markRewardsAsSeen({
            variables: {
              verifiedDealIds,
            },
          });
          setDidSendSeenMutation(true);
        } catch (error) {
          Logger.error(
            `Failed to mark Verified Deals as seen: ${verifiedDealIds.join(
              ', '
            )}`,
            error
          );
        }
      }
    };
    runSeenEffect();
  }, [rewards, markRewardsAsSeen, didSendSeenMutation, onOffersMarkedAsSeen]);
};
