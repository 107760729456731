import { HTMLAttributes, ReactElement } from 'react';

import Footer, { FooterOptions } from 'components/Footer/Footer';

import {
  SiteFurnitureContextValue,
  useSiteFurnitureContext,
} from 'lib/context/SiteFurnitureContext';

export const TEST_ID = 'FooterContainer';

export interface FooterContainerProps extends HTMLAttributes<HTMLDivElement> {
  options?: FooterOptions;
}

export const FooterContainer = ({
  className,
  options,
}: FooterContainerProps): ReactElement | null => {
  const { available, footerData }: SiteFurnitureContextValue =
    useSiteFurnitureContext();

  if (!available || !footerData) {
    return null;
  }

  return (
    <Footer
      className={className}
      data-testid={TEST_ID}
      options={options}
      {...footerData}
    />
  );
};

export default FooterContainer;
