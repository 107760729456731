import {
  differenceInDays,
  formatDistanceToNow,
  format,
  differenceInMinutes,
  differenceInHours,
} from 'date-fns';
import { ReactNode } from 'react';

import { LiveShopDiscountDetail } from 'explorer/lib/hooks/useLiveShopDiscountDetail';

import Logger from 'lib/utils/Logger';

import { CartLineItem } from 'data/graphql/types';
import { WebOfferCenterGetVerifiedDealsQuery } from 'types/generated/api';

import LockIcon from 'assets/images/lock-icon.inline.svg';

import styles from './Reward.module.scss';

export enum RedemptionState {
  APPLIED = 'APPLIED',
  AVAILABLE = 'AVAILABLE',
  DISABLED = 'DISABLED',
  LOADING = 'LOADING',
}

export const EXPIRES_IN_A_MINUTE_STRING = 'Expires in 1 minute';
export const EXPIRED_STRING = 'Expired';

/**
 * Array containing type identifier prefixes of rewards that are only available for
 * redemption on native mobile apps
 */
export const APP_ONLY_OFFER_TYPE_PREFIXES = ['MOBILE_APP_REGISTRATION'];

/**
 * Returns a boolean designating if this reward is only redeemable on
 * native mobile apps
 * @param dealType the 'type' identifier from `VerifiedDeal`
 * @returns Boolean
 */
export const isOnlyAvailableOnApp = (dealType: string): boolean => {
  return APP_ONLY_OFFER_TYPE_PREFIXES.some(type => dealType.startsWith(type));
};

export const getRedemptionStateCopy = (
  currentState: RedemptionState
): string => {
  switch (currentState) {
    case RedemptionState.LOADING: {
      return 'Loading...';
    }

    case RedemptionState.APPLIED: {
      return 'Applied';
    }

    case RedemptionState.DISABLED:
    case RedemptionState.AVAILABLE:
    // Adding default here to appeasse the typescript deities
    default: {
      return 'Apply';
    }
  }
};

export const isRewardLocked = (
  reward: DealEntryFromGetVerifiedDealsQuery
): boolean => {
  const unlockDate = new Date(reward?.unlocksAt || 0);
  const now = new Date();
  return unlockDate > now;
};

export const getPrimarySectionCopy = (
  reward: DealEntryFromGetVerifiedDealsQuery,
  liveShopDiscountDetail?: LiveShopDiscountDetail
): ReactNode => {
  if (!reward) {
    return null;
  }

  if (isRewardLocked(reward)) {
    return <h4 className={styles.offerRightAmount}>{reward.description}</h4>;
  }

  if (liveShopDiscountDetail) {
    return (
      <h4 className={styles.offerRightLiveShopPrimaryCopy}>
        15% Off - Livestream Deal
      </h4>
    );
  }

  let copy =
    reward.valueType === 'PERCENTAGE'
      ? `${reward.value}% Off `
      : `$${reward.value} Off `;

  if (reward && reward.minimumSpend) {
    copy += `($${reward.minimumSpend} Min.)`;
  }
  return <h4 className={styles.offerRightAmount}>{copy}</h4>;
};

export const getSecondarySectionCopy = (
  reward: DealEntryFromGetVerifiedDealsQuery,
  liveShopDiscountDetail?: LiveShopDiscountDetail
): ReactNode => {
  if (!reward) {
    return null;
  }
  const expirationDate = new Date(reward?.expiresAt);
  if (expirationDate.toString() === 'Invalid Date') {
    return null;
  }

  const isIosOnly = isOnlyAvailableOnApp(reward?.type || '');
  const isLocked = isRewardLocked(reward);

  if (isIosOnly) {
    return (
      <p className={styles.offerRightExpiration}>
        <LockIcon className={styles.lockIcon} />
        Sign in on iOS to claim
      </p>
    );
  }

  if (liveShopDiscountDetail) {
    return (
      <p className={styles.offerRightLiveShopSecondaryCopy}>
        <span className={styles.offerRightLiveShopStreamer}>
          {liveShopDiscountDetail.streamer}
        </span>
        {' - '}
        {liveShopDiscountDetail.title}
      </p>
    );
  }

  if (isLocked) {
    if (typeof reward.unlocksAt !== 'string') {
      throw Error(
        `unlocksAt value must be a string. Found on reward with id: ${reward.id}`
      );
    }
    return (
      <p className={styles.offerRightExpiration}>
        <LockIcon className={styles.lockIcon} />
        Surprise reward unlocks in{' '}
        {formatDistanceToNow(new Date(reward.unlocksAt as string))}
      </p>
    );
  }

  return (
    <p className={styles.offerRightExpiration}>
      Valid until {format(expirationDate, 'MMMM dd, yyyy')}
    </p>
  );
};

export const getLineItemTotal = (lineItems: CartLineItem[] = []): number => {
  return lineItems.reduce((acc, lineItem) => {
    return acc + Number(lineItem.price) * lineItem.quantity;
  }, 0);
};

export type DealsFromGetVerifiedDealsQuery =
  WebOfferCenterGetVerifiedDealsQuery['verifiedDeals']['deals'];

export type DealEntryFromGetVerifiedDealsQuery =
  DealsFromGetVerifiedDealsQuery[number];

/**
 * Returns a reward to tease to the user. Business logic says
 * the teased reward should be the reward that will unlock the soonest out
 * of all of the rewards that are currently locked.
 * @param rewards - VerifiedDeal items to send
 */
export const getRewardToTease = (
  rewards: DealsFromGetVerifiedDealsQuery = []
): DealEntryFromGetVerifiedDealsQuery | undefined => {
  const teasedDeal = rewards
    .filter(reward => reward && isRewardLocked(reward))
    .sort((a, b) => {
      const aDate = new Date((a && a.unlocksAt) || 0);
      const bDate = new Date((b && b.unlocksAt) || 0);
      return aDate.getTime() - bDate.getTime();
    });

  return teasedDeal[0];
};

export const generateRewardExpirationMessage = (
  expirationDate: Date,
  currentDate: Date
): string => {
  const remainingDays = differenceInDays(expirationDate, currentDate);
  if (!remainingDays && remainingDays !== 0) {
    Logger.error(
      `Reward expiration data invalid: {expirationDate: ${expirationDate}, currentDate: ${currentDate}}`
    );
    return '';
  }
  if (remainingDays > 7) {
    return '';
  }
  if (remainingDays < 0) {
    // Included as a backup but expired rewards should be filtered out before ever making it here
    return EXPIRED_STRING;
  }

  if (remainingDays > 0) {
    return `Expires in ${remainingDays} day${remainingDays === 1 ? '' : 's'}`;
  }
  const remainingHours = differenceInHours(expirationDate, currentDate);
  if (remainingHours > 0) {
    return `Expires in ${remainingHours} hour${
      remainingHours === 1 ? '' : 's'
    }`;
  }
  const remainingMinutes = differenceInMinutes(expirationDate, currentDate);
  if (remainingMinutes > 0) {
    return `Expires in ${remainingMinutes} minute${
      remainingMinutes === 1 ? '' : 's'
    }`;
  }
  return EXPIRES_IN_A_MINUTE_STRING; // remaining time between 0 - 59 secs, just show 1 min
};

/* prioritize offers by the largest matched or the easiest to reach */
export const findRewardToNotify = (
  cartTotal: number,
  redeemableOffers: DealsFromGetVerifiedDealsQuery = []
): DealEntryFromGetVerifiedDealsQuery => {
  /* prioritize the largest matched or the easiest to reach offer*/
  return redeemableOffers
    .filter(reward => reward && !isRewardLocked(reward))
    .sort((a, b) => {
      const valA = a?.minimumSpend || 0;
      const valB = b?.minimumSpend || 0;

      if (valA <= cartTotal) {
        if (valB <= cartTotal) {
          // descending
          return valB - valA;
        } else {
          return -1;
        }
      } else {
        if (valB >= cartTotal) {
          // ascending
          return valA - valB;
        } else {
          return 1;
        }
      }
    })[0];
};
