import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import TextButton from 'components/Buttons/TextButton/TextButton';
import IDefaultComponentProps from 'components/defaultComponentProps';
import { Column, Container, Hidden, Row, Visible } from 'components/Grid';
import { SCROLL_STATE } from 'components/Header/HeaderContainer';

import commonRichTextTypes from 'lib/contentful/commonRichTextTypes/commonRichTextTypes';
import { useInfoBarData } from 'lib/hooks/siteFurniture/useInfoBarData';
import Logger from 'lib/utils/Logger';

import { InfoBarProps } from 'types/app';

import styles from './InfoBar.module.scss';

const HolidayShippingModalLink = dynamic(
  () => import('components/HolidayShippingModalLink')
);

type InfoBarUiProps = InfoBarProps & IDefaultComponentProps;

// Make links to "help" center open in new tab
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: commonRichTextTypes.renderNode[INLINES.HYPERLINK],
  },
};

const InfoBarUi = (props: InfoBarUiProps) => {
  const {
    centerContent,
    className,
    leftSideContent,
    rightSideContent,
    shouldRenderHolidayShippingInfo,
  } = props;
  const modeClassName = shouldRenderHolidayShippingInfo
    ? styles.importantInfoMode
    : styles.normalMode;

  const classname = classnames(styles.root, modeClassName, className);

  return (
    <div className={classname}>
      <Container>
        <Row>
          <Column xs={12}>
            <div className={styles.content}>
              <Visible xs>
                <span className={styles.leftContent}>
                  {shouldRenderHolidayShippingInfo ? (
                    <HolidayShippingModalLink
                      className={styles.holidayShippingModalLink}
                    />
                  ) : (
                    documentToReactComponents(leftSideContent, options)
                  )}
                </span>
                <span className={styles.centerContent} />
                <span className={styles.rightContent}>
                  <TextButton className={styles.helpModalButton}>
                    {documentToReactComponents(rightSideContent, options)}
                  </TextButton>
                </span>
              </Visible>

              <Hidden xs>
                <span className={styles.leftContent}>
                  {shouldRenderHolidayShippingInfo ? (
                    <HolidayShippingModalLink
                      className={styles.holidayShippingModalLink}
                    />
                  ) : (
                    documentToReactComponents(leftSideContent, options)
                  )}
                </span>
                <span className={styles.centerContainer}>
                  {centerContent && (
                    <div className={styles.centerContent}>
                      {documentToReactComponents(centerContent, options)}{' '}
                    </div>
                  )}
                </span>
                <span className={styles.rightContent}>
                  {documentToReactComponents(rightSideContent, options)}
                </span>
              </Hidden>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

type WithDataErrorHandlingProps = {
  openNewsletterModal: () => void;
  scrollState?: SCROLL_STATE;
} & IDefaultComponentProps;

const InfoBarWithCmsData = (props: WithDataErrorHandlingProps) => {
  const { error, infoBarData, loading } = useInfoBarData();

  try {
    const { className, openNewsletterModal, scrollState } = props;

    if (loading) {
      return null; // no loading indicator is requested yet
    }

    if (error) {
      throw error;
    }

    const infoBarProps = infoBarData;

    if (!infoBarProps) {
      Logger.warn(
        'Something went wrong trying to render new InfoBar, rendering Old Infobar...',
        error
      );
      return null;
    }

    const infobarClasses = classnames(className, {
      [styles.shadowBottom]: scrollState !== SCROLL_STATE.SCROLLED_DOWN,
    });

    return (
      <InfoBarUi
        className={infobarClasses}
        openNewsletterModal={openNewsletterModal}
        {...infoBarProps}
      />
    );
  } catch {
    return null;
  }
};

export default InfoBarWithCmsData;
