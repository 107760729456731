import classnames from 'classnames';
import { Fragment, MouseEvent } from 'react';

import IDefaultComponentProps from 'components/defaultComponentProps';
import LocalizableLink, {
  ILocalizableLinkProps,
} from 'components/LocalizableLink/LocalizableLink';

import { BrandLink } from 'lib/links';

import styles from './BrandLetterLinksIndex.module.scss';

export const SHORTCUT_LETTERS =
  '0-9, ,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',');

export type BrandLetterLinksIndexProps = {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  summaryLinkProps?: ILocalizableLinkProps;
} & IDefaultComponentProps;

const BrandLetterLinksIndex = ({
  className,
  onClick,
  summaryLinkProps,
}: BrandLetterLinksIndexProps) => {
  return (
    <div
      className={classnames(styles.root, className)}
      onClick={onClick}
      role="presentation"
    >
      <div className={styles.shortcutLinksContainer}>{renderLetterLinks()}</div>
      {summaryLinkProps && (
        <LocalizableLink className={styles.summaryLink} {...summaryLinkProps} />
      )}
    </div>
  );
};

export const renderLetterLinks = () => {
  return (
    <Fragment>
      {SHORTCUT_LETTERS.map((letter: string) => {
        return (
          <div className={styles.shortcutLink} key={letter}>
            {letter && (
              <BrandLink startsWith={letter}>
                <a className={styles.anchor} href="placeholder">
                  {letter.toUpperCase()}
                </a>
              </BrandLink>
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default BrandLetterLinksIndex;
