import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { ClientOnly } from 'components/ClientOnly/ClientOnly';
import { GET_ME, GetMeResponse } from 'data/graphql/queries';

import { checkIsUserLoggedIn } from 'lib/auth';
import { useSyncCart } from 'lib/hooks/cart/useSyncCart';
import Logger from 'lib/utils/Logger';

const CartIdSynchronizer = () => {
  const router = useRouter();
  const apolloClient = useApolloClient();

  const syncCart = useSyncCart();

  const getCheckoutIdFromApiAndSyncCart = useCallback(async () => {
    const isUserLoggedIn = await checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
      return;
    }

    const meResponse = await apolloClient.query<GetMeResponse>({
      query: GET_ME,
    });

    if (isEmpty(meResponse.errors)) {
      const checkoutIdFromApi = meResponse.data?.me?.shopifyCheckoutId;
      await syncCart(checkoutIdFromApi);
    } else {
      const error = new Error(JSON.stringify(meResponse.errors));
      Logger.error(
        'Error fetching ME in getCheckoutIdFromApiAndSyncCart',
        error
      );
    }
  }, []);

  // This Effect will only run on the initial page load and when the router.aspath changes.
  // We do not want this effect to be reactive to the meResponse since we do not want the
  // effect to run when ME changes. We manually rerun the cartSync when the user logs in.
  // TODO: [ch5583] - design a solution that does not rely on page render
  useEffect(() => {
    getCheckoutIdFromApiAndSyncCart().catch(error => {
      Logger.error('Error in CartIdSynchronizer', error);
    });
  }, [getCheckoutIdFromApiAndSyncCart, router.asPath]);

  return null;
};

export const CartIdSynchronizerClientOnly = () => {
  return (
    <ClientOnly>
      <CartIdSynchronizer />
    </ClientOnly>
  );
};

export default CartIdSynchronizerClientOnly;
