import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  FC,
  ReactElement,
  UIEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import IconButton from 'components/Buttons/IconButton';
import IDefaultComponentProps from 'components/defaultComponentProps';
import { Hidden, Visible } from 'components/Grid';
import LocalizableLink, {
  ILocalizableLinkProps,
} from 'components/LocalizableLink/LocalizableLink';
import Modal from 'components/Modals/Modal/Modal';
import { ProfilePhoto } from 'explorer/components/ProfilePhoto/ProfilePhoto';

import { useSiteFurnitureContext } from 'lib/context/SiteFurnitureContext';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';
import Logger from 'lib/utils/Logger';

import CloseIcon from 'assets/icons/ic-close-no-stroke.inline.svg';

import styles from './SiteWideBanner.module.scss';

const DEFAULT_BACKGROUND_COLOR = '#e8e8e8';
const DEFAULT_FONT_COLOR = '#333333';

export type SiteWideBannerModalProps = {
  content: Document;
  header: string;
};

type OwnProps = {
  backgroundColorCss?: string;
  bannerKeyName?: string;
  bannerLink?: ILocalizableLinkProps;
  ctaLinkText?: string;
  fontColorCss?: string;
  header: string | ReactElement;
  imageUrl?: string;
  mobileHeader: string | ReactElement;
  modal?: SiteWideBannerModalProps;
  suppressDismissIcon?: boolean;
};

export type SiteWideBannerProps = OwnProps & IDefaultComponentProps;

const CLOSE_BUTTON_LABEL = 'Close';

export const SiteWideBannerUI: FC<SiteWideBannerProps> = (
  props: SiteWideBannerProps
) => {
  const { bannerKeyName = '', suppressDismissIcon = false } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerDismissed, setIsBannerDismissed] = useState(
    getIsBannerDismissedFromStorage(bannerKeyName)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnModalCloseClick = useCallback(
    () => setIsModalOpen(false),
    [isModalOpen]
  );

  const handleBannerClick = useCallback((e: UIEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(true);
  }, []);

  const handleBannerKeypress = () => {
    setIsModalOpen(true);
  };

  const handleDismissButtonClick = useCallback(
    () => setIsBannerDismissed(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBannerDismissed]
  );

  useEffect(
    () =>
      setIsBannerDismissedToStorage(
        isBannerDismissed,
        bannerKeyName,
        !suppressDismissIcon
      ),
    [isBannerDismissed, bannerKeyName, suppressDismissIcon]
  );

  try {
    const {
      backgroundColorCss = DEFAULT_BACKGROUND_COLOR,
      bannerLink,
      className,
      ctaLinkText,
      fontColorCss = DEFAULT_FONT_COLOR,
      header,
      mobileHeader,
      modal,
    } = props;

    if (isBannerDismissed) {
      return null;
    }

    const renderBannerContent = () => {
      const hasBannerLink = !isEmpty(bannerLink);
      const hasModal = !isEmpty(modal);

      const linkBanner = !hasModal && hasBannerLink;
      const modalBanner = !hasBannerLink && hasModal;
      const linkAndModalBanner = hasBannerLink && hasModal;

      const headerImage = (
        <>
          {props.imageUrl && (
            <div className={styles.headerImageContainer}>
              <ProfilePhoto
                altText="profile image"
                profilePhotoUrl={props.imageUrl}
                width={30}
              />
            </div>
          )}
        </>
      );

      const bannerHeader = (
        <span className={styles.header} style={{ color: fontColorCss }}>
          <Visible xs>{mobileHeader}</Visible>
          <Hidden xs>{header}</Hidden>
        </span>
      );
      let bannerContent = bannerHeader;

      if (!isEmpty(ctaLinkText)) {
        if (linkBanner && bannerLink) {
          bannerContent = (
            <LocalizableLink className={styles.bannerClickArea} {...bannerLink}>
              <div className={styles.bannerContentContainer}>
                {headerImage}
                {bannerHeader}
                <span className={styles.ctaText}>{ctaLinkText}</span>
              </div>
            </LocalizableLink>
          );
        } else if (modalBanner) {
          bannerContent = (
            <div className={styles.bannerContentContainer}>
              {headerImage}
              {bannerHeader}
              <span
                className={styles.ctaLink}
                onClick={handleBannerClick}
                onKeyPress={keyPressHandler({
                  Enter: handleBannerKeypress,
                })}
                role="button"
                style={{ color: fontColorCss }}
                tabIndex={0}
              >
                {ctaLinkText}
              </span>
            </div>
          );
        } else if (linkAndModalBanner && bannerLink) {
          bannerContent = (
            <LocalizableLink className={styles.bannerClickArea} {...bannerLink}>
              <div className={styles.bannerContentContainer}>
                {headerImage}
                {bannerHeader}
                {ctaLinkText && (
                  <span
                    className={styles.ctaLink}
                    onClick={handleBannerClick}
                    onKeyPress={keyPressHandler({
                      Enter: handleBannerKeypress,
                    })}
                    role="button"
                    style={{ color: fontColorCss }}
                    tabIndex={0}
                  >
                    {ctaLinkText}
                  </span>
                )}
              </div>
            </LocalizableLink>
          );
        }
      }

      return bannerContent;
    };

    return (
      <div
        className={classnames(styles.root, className)}
        style={{ backgroundColor: backgroundColorCss }}
      >
        {renderBannerContent()}
        {!suppressDismissIcon && (
          <IconButton
            IconComponent={() => getStyledDismissBannerIcon(fontColorCss)}
            aria-label={CLOSE_BUTTON_LABEL}
            className={styles.dismissButtonContainer}
            onClick={handleDismissButtonClick}
          />
        )}
        {modal && (
          <Modal
            header={modal.header}
            isOpen={isModalOpen}
            onCloseClick={handleOnModalCloseClick}
          >
            {documentToReactComponents(modal.content)}
          </Modal>
        )}
      </div>
    );
  } catch (error) {
    if (error instanceof Error) {
      Logger.warn(
        `Unable to render SiteWideBanner with props: ${JSON.stringify(props)}`,
        error
      );
    }

    return null;
  }
};

// using this for custom styling
const getStyledDismissBannerIcon = (fillColor?: string) => (
  <CloseIcon className={styles.dismissButtonIcon} stroke={fillColor} />
);

const getIsBannerDismissedFromStorage = (
  isBannerDismissedStorageKey: string
): boolean => {
  // use session storage to store a flag, indicating whether the banner is dismissed
  try {
    return sessionStorage.getItem(isBannerDismissedStorageKey) === 'true';
  } catch (error) {
    // either it's not set or we are on a server, no need for logging, just return false
    return false;
  }
};

const setIsBannerDismissedToStorage = (
  isBannerDismissed: boolean,
  isBannerDismissedStorageKey: string,
  isDismissEnabled: boolean
) => {
  if (isDismissEnabled) {
    return;
  }
  try {
    return sessionStorage.setItem(
      isBannerDismissedStorageKey,
      `${isBannerDismissed}`
    );
  } catch (error) {
    if (error instanceof Error) {
      Logger.warn(
        'Unable to store isSiteWideBannerDismissed flag in session storage',
        error
      );
    }

    return;
  }
};

const WrappedComponent = (
  props: { isSiteWideBanner2?: boolean } & IDefaultComponentProps
): ReactElement | null => {
  const siteFurnitureData = useSiteFurnitureContext();
  try {
    if (siteFurnitureData.available === false) {
      return null;
    }

    const dataProps = props.isSiteWideBanner2
      ? siteFurnitureData?.siteWideBanner2
      : siteFurnitureData?.siteWideBanner;

    if (!dataProps || isEmpty(dataProps)) {
      return null;
    }

    return <SiteWideBannerUI {...props} {...dataProps} />;
  } catch (error) {
    if (error instanceof Error) {
      Logger.error(
        'Unable to render SiteWideBanner component due to an error.',
        error
      );
    }

    return null;
  }
};

export default WrappedComponent;
